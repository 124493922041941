import React, { useEffect, useRef } from 'react'
import { ExtractRouteParams, OmitNative, RouteProps } from 'react-router'
import { Route as RRRoute, Redirect } from 'react-router-dom'

import { ShortUrl } from 'pages/ShortUrl'
import { AssignmentsIndex } from 'pages/assignments/Index'
import { AssignmentOrderShow } from 'pages/assignments/OrderShow'
import { AssignmentShow } from 'pages/assignments/Show'
import { OrderShow } from 'pages/orders/Show'

import { IonPage } from '@ionic/react'
import * as analytics from 'helpers/analytics'
import { useSession } from 'hooks/useSession'
import { useToast } from './hooks/useToast'
import { DownloadApp } from './pages/DownloadApp'
import { ProfileIndex } from './pages/profile/Index'
import { TimeclockIndex } from './pages/timeclock/Index'
import { TimeClockHistoryIndex } from './pages/timeclock/history/Index'

export const Routes = () => [
  makeRoute({
    path: '/download',
    exact: true,
    component: DownloadApp,
  }),
  makeRoute({
    path: '/assignments/:assignmentId/orders/:orderId',
    exact: true,
    component: AssignmentOrderShow,
    numericParams: ['assignmentId', 'orderId'],
  }),
  makeRoute({
    path: '/assignments/:assignmentId',
    exact: true,
    component: AssignmentShow,
    numericParams: ['assignmentId'],
  }),
  makeRoute({
    path: '/:tab(assignments)',
    exact: true,
    component: AssignmentsIndex,
  }),
  makeRoute({
    path: '/orders/:orderId',
    component: OrderShow,
    numericParams: ['orderId'],
  }),
  makeRoute({
    path: '/timeclock',
    exact: true,
    component: TimeclockIndex,
  }),
  makeRoute({
    path: '/timeclock/history',
    component: TimeClockHistoryIndex,
  }),
  makeRoute({
    path: '/profile',
    component: ProfileIndex,
  }),
  <RRRoute key="fallback" component={FallbackRoute} />,
]

// wtf: https://github.com/ionic-team/ionic-framework/issues/23743
const FallbackRoute = (propsInput: any) => {
  const props = useRef(propsInput).current
  const path = props.location.pathname
  const pathParts = path.split('/').slice(1)

  const isSlugLike = pathParts.length === 1 && pathParts[0].length > 0

  useEffect(() => {
    if (!isSlugLike) {
      // TODO https://github.com/ionic-team/ionic-framework/issues/23743
      // this should be a redirect
      window.location.replace('/assignments')
    }
  }, [path])

  if (isSlugLike) {
    return <ShortUrl slug={pathParts[0]} />
  }

  return null
}

let routeCounter = 0

function makeRoute<T extends {} = {}, Path extends string = string>(
  props: RouteProps<Path> & OmitNative<T, keyof RouteProps> & {
    numericParams?: Array<keyof ExtractRouteParams<Path, any>>
  }
) {
  const {
    numericParams,
    render,
    component: Component,
    ...rest
  } = props

  const routeArgs = rest as RouteProps<Path> & OmitNative<T, keyof RouteProps>

  const wrappedRender: typeof render = (renderProps) => {
    analytics.trackPageView({
      page_title: Component?.name || 'Unknown',
      page_location: `${window.location.origin}${renderProps.location.pathname}`,
      page_path: renderProps.location.pathname,
    })

    const { params } = renderProps.match

    numericParams?.forEach((param) => {
      if (params[param]) {
        const parsed = parseFloat(params[param] as any)
        if (!Number.isNaN(parsed)) {
          params[param] = (parsed as any)
        }
      }
    })

    return (
      <LoggedIn>
        <IonPage>
          {
            render ?
              render({
                ...renderProps,
                match: {
                  ...renderProps.match,
                  params,
                },
              })
              : Component ?
                <Component {...params as any} />
                : null
          }
        </IonPage>
      </LoggedIn>
    )
  }

  const key = [props.path].flat().join('/') + routeCounter.toString()
  routeCounter += 1

  return <RRRoute key={key} {...routeArgs} render={wrappedRender} />
}

const LoggedIn = ({ children }: React.PropsWithChildren<any>) => {
  const { loggedIn } = useSession()
  const [presentToast] = useToast()

  useEffect(() => {
    if (!loggedIn) {
      presentToast({
        message: 'Please login to continue',
        position: 'top',
        duration: 3000,
        color: 'medium',
      })
    }
  }, [loggedIn])

  if (!loggedIn) {
    return <Redirect to="/auth/login" />
  }

  return <>{children}</>
}
