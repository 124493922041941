import { Capacitor } from '@capacitor/core'
import {
  IonContent, IonPage, IonSpinner, isPlatform,
} from '@ionic/react'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const IOS_APP_ID = 'id6714483478'
const ANDROID_PACKAGE_NAME = 'com.cretesuite.crewapp'

const RedirectTextWrapper = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`
const RedirectText = styled.label`
  font-weight: 500;
  padding-bottom: 10px;
  margin-right: 4px;
`

export const DownloadApp = () => {
  const history = useHistory()
  const hasExecuted = useRef(false)

  const goToAppStore = () => {
    if (Capacitor.isNativePlatform()) {
      return history.replace('/')
    }

    let storeUrl = ''
    if (isPlatform('ios')) {
      storeUrl = `https://apps.apple.com/app/${IOS_APP_ID}`
    } else if (isPlatform('android')) {
      storeUrl = `https://play.google.com/store/apps/details?id=${ANDROID_PACKAGE_NAME}`
    }
    if (storeUrl) {
      window.open(storeUrl, '_self') // open the app store
      setTimeout(() => {
        history.replace('/')
      }, 3000)
      return
    }
    history.replace('/')
  }

  useEffect(() => {
    if (!hasExecuted.current) {
      hasExecuted.current = true
      goToAppStore()
    }
  }, [])

  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <RedirectTextWrapper>
          <RedirectText>Redirecting</RedirectText><IonSpinner name="dots" />
        </RedirectTextWrapper>
      </IonContent>
    </IonPage>
  )
}
